.TrainingPlanManager img {
    max-width: -webkit-fill-available;
    min-width: 0px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.DropDownSection {
    padding-bottom: 15px;
}

.DropDownSection span {
    margin-right: 2px;
}

.DetailsSection div {
    padding-bottom: 15px;
}

.DetailsSection span {
    margin-right: 20px;
}

.rc-highlight-custom {
    background-color: #60ff60;
    transition: all 0.5s ease-in-out;
}

.bodyFont {
    font-family: "-apple-system,BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.pageMargin {
    margin: 35px;
}

.signature-fields {
    display: none;
}

@media print {
    .pageMargin {
        margin: 1px;
    }
    .signature-fields {
        display: table;
    }
    .signature-fields table {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
    }
    .signature-fields th {
        border: 1px solid #dee2e6;
        text-align: center;
        padding-top: 10px;
    }
    .signature-fields td {
        border: 1px solid #dee2e6;
        height: 40px;
    }
}

.fixed-content {
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.header-accordion-table {
    float: right;
    width: 73%;
}