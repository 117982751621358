.gridTable {
    width: auto;
    border: 1px solid lightgrey;
}

.gridTable td,
th {
    text-align: center;
    border: 1px solid lightgrey;
}

tr.pito-tr-dirty {
    background: #eff5eb !important;
}

.lds-dual-ring {
    display: inline-block;
    width: 30px;
    height: 30px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 25px;
    height: 25px;
    /*margin: 8px;*/
    border-radius: 50%;
    border: 3px solid #00bfff;
    border-color: #00bfff transparent #00bfff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.uk-alert-warning {
    background: #fffceb;
    color: #e28327;
    margin-bottom: 5px;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.list {
    user-select: none;
}

.dragged {
    background-color: orange !important;
}

.mahi-tahi-col {
    border: 1px solid lightgrey;
    text-align: center;
}

.mahi-tahi-row {
    cursor: grab;
    border: 1px black;
}

.goals-row {
    border: 4px black;
}

.visit-row {
    background-color: rgb(243, 242, 241);
    border: 4px black;
}

.goals-col {
    border: 1px solid lightgrey;
    padding: 4px;
}

.mahi-tahi-dirty {
    background: #eff5eb !important;
}

.unitstandard-div {
    text-align: left !important;
}

.grid-button {
    display: "";
}

.dragAndDropGridPDF table tr> :nth-child(1) {
    display: none;
}

.dragAndDropGridPDF .assessor {
    display: none;
}

.dragAndDropGridPDF .verifier {
    display: none;
}

.dragAndDropGridPDF .grid-button {
    display: none;
}   

#dragAndDropGrid table td, #dragAndDropGridPDF table td{
    background-color: white;
    border: 1px #dee2e6 solid;
    border-right: 0px;
    border-bottom: 1px;
    border-collapse: collapse;
    padding-top: 0px;
    padding-bottom: 0px;
}

.courseInfoGrid table tr> :nth-child(1) {
    width: 700px;
}

#dragAndDropGridPortal table td {
    border: 1px #dee2e6 solid;
    border-right: 0px;
    border-bottom: 1px;
    border-collapse: collapse;
    padding-top: 0px;
    padding-bottom: 0px;
}

@media print {
    
    .grid-button{
        display: none;
    }
    .sort-icon {
        visibility:hidden;
        opacity: 0;
        color: transparent;
    }
    #courseInfoGrid table tr> :nth-child(3) {
        width:500px;
    } 
    #dragAndDropGrid table tr> :nth-child(1) {
        display: none;
    }
    #dragAndDropGrid table tr> :nth-child(3) {
        width:500px;
    }
    #dragAndDropGrid table tr> :nth-child(4) {
        width: 20px;
    }

    #dragAndDropGrid .assessor,
    #dragAndDropGrid .verifier,
    #dragAndDropGrid .lmsstatus {
        display: none;
    }

    #dragAndDropGridPortal table tr> :nth-child(8) {
        display: none;
    }
    #dragAndDropGridPortal table tr> :nth-child(9) {
        display: none;
    }
    #dragAndDropGridPortal {
        overflow-y: hidden;
        /* Hide vertical scrollbar */
    }
    #dragAndDropGridPortal table tr td p {
        font-size: 7px;
    }
    #dragAndDropGrid {
        overflow-y: hidden;
        /* Hide vertical scrollbar */
    }
    .dragAndDropGrid table tr td p {
        font-size: 10px;
    }
    #courseInfoGrid table tr td p {
        font-size: 12px;
    }

    
}