.show-on-print  {
  display: none;
}

.hide-on-print {
  display: block;
}

.show-on-print-print {
  display: block;
}

.hide-on-print-print {
  display: none;
}


@media print {
    .show-on-print {
      display: block;
    }
    .hide-on-print {
      display: none;
    }
    .col-print-7 {
      max-width: 70% !important;
      flex: 0 0 100%;
    }

}