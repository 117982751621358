table {
    page-break-inside: auto;
}

tr {
    page-break-inside: avoid;
    page-break-after: auto;
}

.summary-row {
    height: 24px;
}

.header-logo {
    width: 250px;
}

.header-text {
    font-size: 30px;
}

.topright {
    position: absolute;
    top: 1%;
    right: 1%;
}

.button-style {
    border-color: #5BB4D8;
}

@media print {
    thead {
        display: table-row-group;
    }
    table {
        page-break-inside: auto;
    }
    table tbody tr td {
        padding: 5px 8px;
    }
    table {
        page-break-inside: auto;
    }
    table tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
    table thead {
        display: table-row-group;
    }
    p {
        font-size: 7pt;
    }
    .summary-row {
        height: 20px;
    }
    .header-logo {
        width: 150px;
    }
    .editPanel {
        display: none;
    }
}

.editPanel {
    display: "";
}

tfoot {
    display: table-footer-group;
}