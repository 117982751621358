.top-accordion {
    /*height: 480px;*/
    overflow-x: hidden;
    overflow-y: hidden;
}

@media print {
    .top-accordion {
        height: 100% !important;
        overflow-x: auto;
        overflow-y: auto;
    }
}