.bodyFont {
    font-family: "-apple-system,BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.pageMargin {
    margin: 35px;
}

@media print {
    .pageMargin {
        margin: 1px;
    }
}

.emptyMessageDiv {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 0.5%;
    width: 100%;
    background: rgb(243, 242, 241);
    height: 45px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(161, 159, 157);
}