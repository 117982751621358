@media print {
    .header-section {
        width:90% !important;
    }
    table tr {
        font-size: 7pt;
    }
    
}

.header-section-print {
    width:90% !important;
}