.text-size {
    font-size: 14px;
}

.anchor-style {
    text-decoration: underline;
}

.anchor-style-print {
    text-decoration: none;
    color: black;
}

@media print {
    .text-size {
        font-size: 6px;
    }
    .anchor-style {
        text-decoration: none;
        color: black;
    }
}